import React from 'react'
import CardList from '../components/CardList'
import Card from '../components/Card'
import Container from '../components/Container'
import styled from "styled-components";
import Layout from '../components/DefaultLayout';
import { graphql } from "gatsby"
import MembershipList from "../components/MembershipList";
import {Helmet} from "react-helmet";


const Hero = styled.img`
  width: 50vw;
  margin: auto;
`

const Index = ({data}) => {
    const posts = data.allContentfulPost.edges;

    return (
        <Layout>
            <Helmet>
                <meta property="og:image" content="https://images.ctfassets.net/v9p9nndrs2kj/v1NzupIR1YWWsKUkKUqyC/3d854faea6474b716f5ccc8bf4d295b2/blackinfinity.svg"/>
            </Helmet>
            <Container>
                <Hero src="https://images.ctfassets.net/v9p9nndrs2kj/v1NzupIR1YWWsKUkKUqyC/3d854faea6474b716f5ccc8bf4d295b2/blackinfinity.svg" alt=""/>
                <MembershipList />
                <CardList>
                    {posts.map(({node: post}) => (
                        <Card
                            key={post.id}
                            slug={post.slug}
                            image={post.heroImage}
                            title={post.title}
                            date={post.publishDate}
                            excerpt={post.body}
                        />
                    ))}
                </CardList>
            </Container>
        </Layout>
    )
}

export const query = graphql`
  query indexQuery {
    allContentfulPost(
      limit: 1000
      sort: { fields: [publishDate], order: DESC }
    ) {
      edges {
        node {
          title
          id
          slug
          publishDate(formatString: "MMMM DD, YYYY")
          heroImage {
            title
            sizes(maxWidth: 800) {
              ...GatsbyContentfulSizes_withWebp_noBase64
            }
          }
          body {
            childMarkdownRemark {
              html
              excerpt(pruneLength: 80)
            }
          }
        }
      }
    }
      
  }
`

export default Index
