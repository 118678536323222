import React from 'react'
import styled from 'styled-components'
import {Link} from 'gatsby'

const List = styled.ul`
  margin: 0 auto 2em auto;
  max-width: ${props => props.theme.sizes.maxWidthCentered};
  display:flex;
  justify-content: center;
  align-items: center;
`

const Logo = styled.img`
    width: 100px;
    margin-right: 10px;
`

const Membership = styled.li`
    display: inline-block;
`


const MembershipList = props => {
    return (
        <List>
            <Membership>
                <a href="https://dwzrv.com/">
                    <Logo src="https://images.ctfassets.net/v9p9nndrs2kj/2pS1hxRLIk004iK2WqQAm/24a71ccc54d457f79cb45c1826c4952d/dwzrv-logo.png?h=250" alt=""/>
                </a>
            </Membership>
            <Membership>
                <a href="https://www.vdh.de/home/">
                    <Logo src="https://images.ctfassets.net/v9p9nndrs2kj/5GyWadILRKC0yOEMOQuOE8/3d2d428493e51d9df911bc655662aba9/VDH_Logo.svg" alt=""/>
                </a>
            </Membership>
            <Membership>
                <a href="http://www.fci.be/de/" >
                    <Logo src="https://images.ctfassets.net/v9p9nndrs2kj/RY6u35YKIgssUQe0kw0Qc/c1a4382b1ea8c57bd6cbccb2cd40d583/FCI_Logo.svg" alt=""/>
                </a>
            </Membership>
        </List>
    )
}

export default MembershipList
